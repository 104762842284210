import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/index.scss';
import { graphql } from 'gatsby';

import Layout from '../../layouts/en';
import SEO from '../../components/seo';
import MarketsSlider from '../../components/markets-slider/markets-slider';
import {
  getPageData,
  getPostData,
  setRtl,
  unsetRtl,
} from '../../helpers/helpers';

const IndexPage = ({ location, data }) => {
  const [windowSize, setWindowSize] = useState(0);
  const isMobile = windowSize <= 1024;
  const wpData = data && getPageData(data, 'newsroom-ar');
  const wpDataPosts = data && getPostData(data);
  useEffect(() => {
    setRtl(location.pathname);
    return () => {
      unsetRtl();
    };
  }, []);

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
  || document.body.clientWidth;
    setWindowSize(width);
  };

  useEffect(() => {
    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);

  if (windowSize === 0) {
    return (<></>);
  }
  const renderSections = () => (
    [
      <MarketsSlider
        activeSection={1}
        isMobile={isMobile}
        index={1}
        data={wpDataPosts}
      />,
    ]
  );
  return (
    <>
      <Layout
        currentSection={1}
        sectionsCount={0}
        location={location}
        isMobile={isMobile}
        showElements={false}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
          }
        }
      >
        <SEO title="Public Market" />
        <div data-currentPage={`currentPage_${1}`}>
          {renderSections().map((section) => section)}
        </div>
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};
IndexPage.defaultProps = {
  data: null,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          acf {
            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
          }
          title
          slug
        }
      }
    }
    allWordpressPost(filter: {categories: {eq: 11}}) {
      edges {
        node {
          title
          id
          template
          content
          slug
          date(locale: "")
          acf {
            slide_title
            slide_text_paragraph_1
            slide_text_paragraph_2
            slide_image {
                alt
                url
              }
          }
        }
      }
    }
  }`;
